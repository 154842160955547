import {
  baseBuy,
  baseSell,
  settledBuy,
  settledLightning,
  settledOnchainWithdraw,
  settledSell,
} from '@noah-labs/fe-shared-data-access-wallet/__tests__';
import { CurrencyCode, FiatCurrencyCode, TransactionStatus } from '@noah-labs/shared-schema-gql';
import { cryptoCurrencyFromCode } from '../cryptoCurrencies';
import { fiatCurrencyFromCode } from '../fiatCurrencies';
import type { TpTransactionUi } from '../transactions/getTransactionUi';
import { getTransactionUi } from '../transactions/getTransactionUi';

export function getMockTransactions(): Record<string, TpTransactionUi> {
  return {
    failedOnchainWithdrawTx: getTransactionUi({
      tx: {
        ...settledOnchainWithdraw,
        Status: TransactionStatus.Failed,
      },
    }),
    pendingBuyTx: getTransactionUi({ tx: baseBuy }),
    pendingSellTx: getTransactionUi({ tx: baseSell }),
    settledBuyTx: getTransactionUi({ tx: settledBuy }),
    settledLightningTx: getTransactionUi({ tx: settledLightning }),
    settledOnchainWithdrawTx: getTransactionUi({ tx: settledOnchainWithdraw }),
    settledSellTx: getTransactionUi({ tx: settledSell }),
  };
}

// mock crypto currencies
export const mockBTC = cryptoCurrencyFromCode(CurrencyCode.BTC);
export const mockUSDC = cryptoCurrencyFromCode(CurrencyCode.USDC);
export const mockUSDT = cryptoCurrencyFromCode(CurrencyCode.USDT);

// mock fiat currencies
export const mockGBP = fiatCurrencyFromCode(FiatCurrencyCode.GBP);
export const mockUSD = fiatCurrencyFromCode(FiatCurrencyCode.USD);
